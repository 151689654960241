import { useAppContext } from '@contexts/AppContext';
import { WindowLocation } from '@reach/router';
import StoreService from '@services/StoreService';
import { AbsPage } from '@src/modules/AnnualBoilerService/Abs';
import { useEffect } from 'react';

interface Props {
  location: WindowLocation;
}

const AbsAgent = (props: Props) => {
  const { verifyNoSignupInLocalStorage } = useAppContext();

  useEffect(() => {
    verifyNoSignupInLocalStorage();
    StoreService.save({
      isHomeRecover: false,
      isAgent: true,
    });
  }, [verifyNoSignupInLocalStorage]);

  return <AbsPage {...props} />;
};

export default AbsAgent;
